/* You can add global styles to this file, and also import other style files */
// @import 'src/theme.less';
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";
@import "../node_modules/ng-zorro-antd/ng-zorro-antd.min.css";
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Roboto:wght@100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Nunito:wght@200&family=Roboto:wght@100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,200&family=Roboto:wght@100&display=swap");

body {
  background-color: #f6f6f6;
  overflow-x: hidden;
}
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

//Employee Dashboard Header  Styles
.global-title {
  color: #7b194c;
  font-family: "Bebas Neue", sans-serif;
}

// EMPLOYEE DASHBOARD BACKGROUND
.mat-body,
.mat-body-2,
.mat-typography .mat-body,
.mat-typography .mat-body-2,
.mat-typography {
  background-color: #f6f6f6;
  overflow-x: hidden;
}

///////////////////////////////////////For Every Placeholder of input field///////////////////////////////////////////////////////
::placeholder {
  color: #717070;
  // font-style: italic;
  // font-size: smaller;
}
//////////////////////////////////////For every placeholder of date field////////////////////////////////////////////////////////
input[type="date"]:in-range::-webkit-datetime-edit-year-field,
input[type="date"]:in-range::-webkit-datetime-edit-month-field,
input[type="date"]:in-range::-webkit-datetime-edit-day-field,
input[type="date"]:in-range::-webkit-datetime-edit-text {
  color: #717070;
  // font-style: italic;
  // font-size: smaller;
}

select {
  // color: grey;
  // color: #717070;
  color: #a1a9b8;
  // font-style: italic;
  font-size: smaller;
}

option {
  color: black;
}

option[default] {
  display: none;
}
/* Italicize the placeholder text */
input[type="date"].placeholder::before {
  content: attr(placeholder);
  // font-style: italic;
  color: #717070; /* Placeholder text color */
}

/* Hide the native placeholder */
input[type="date"]::-webkit-input-placeholder {
  opacity: 0;
}
::ng-deep .ui-selectbutton-label {
  font-family: "Arial", sans-serif; /* Change the font family */
  font-size: 16px; /* Change the font size */
  color: #0d99ff;
}
/* Targeting the modal backdrop */
body > ngb-modal-backdrop {
  z-index: 800 !important;
}

/* Targeting the modal window */
body > ngb-modal-window {
  z-index: 800 !important;
}

.pi {
  cursor: pointer;
}
